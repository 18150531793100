import { Upload } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function AdminReports() {
  const [loading, setLoading] = useState(false);

  const handleFunctionExecution = async (name) => {
    setLoading(true);
    const functions = getFunctions();
    const executeFunction = httpsCallable(functions, name);

    const result = await executeFunction();

    window.alert(result.data.message);
    setLoading(false);
  };

  return (
    <div>
      <h1>Admin Only Report Syncs</h1>
      {loading && <LinearProgress />}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="MyPAV Agent Reports" />
            <CardContent>
              <Button
                disabled={loading}
                variant="contained"
                startIcon={<Upload />}
                onClick={() =>
                  handleFunctionExecution("manualRunAgentDailyStats")
                }
              >
                Sync Now
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Connect Agent Reports" />
            <CardContent>
              <Button
                disabled={loading}
                variant="contained"
                startIcon={<Upload />}
                onClick={() =>
                  handleFunctionExecution("conAgentsByDealerDailyManual")
                }
              >
                Sync Now
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* manualDailyDealerStats */}
          <Card>
            <CardHeader title="Connect Dealer Reports" />
            <CardContent>
              <Button
                disabled={loading}
                variant="contained"
                startIcon={<Upload />}
                onClick={() =>
                  handleFunctionExecution("manualDailyDealerStats")
                }
              >
                Sync Now
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
