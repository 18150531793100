import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminHome from "../components/admin/AdminHome";
import AddNetworkOffering from "../components/networks/AddNetworkOffering";
import NetworkOfferings from "../components/networks/NetworkOfferings";
import EditNetworkOffering from "../components/networks/EditNetworkOffering";
import AddGuide from "../components/guides/AddGuide";
import Guides from "../components/guides/Guides";
import EditGuide from "../components/guides/EditGuide";
import AddTemplate from "../components/templates/AddTemplate";
import Templates from "../components/templates/Templates";
import EditTemplate from "../components/templates/EditTemplate";
import AddMedia from "../components/media/AddMedia";
import Media from "../components/media/Media";
import EditPost from "../components/posts/EditPost";
import Posts from "../components/posts/Posts";
import RefactorAddPost from "../components/posts/RefactorAddPost";
import Users from "../components/users/Users";
import AddUser from "../components/users/AddUser";
import Support from "../components/support/Support";
import SupportDetail from "../components/support/SupportDetail";
import AdminReports from "../components/admin/reports/AdminReports";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<AdminHome />} />
      <Route
        exact
        path="/add-network-offering"
        element={<AddNetworkOffering />}
      />
      <Route exact path="/network-offerings" element={<NetworkOfferings />} />
      <Route
        exact
        path="/edit-network-offering/:offeringId"
        element={<EditNetworkOffering />}
      />
      <Route exact path="/add-guide" element={<AddGuide />} />
      <Route exact path="/guides" element={<Guides />} />
      <Route exact path="/edit-guide/:guideId" element={<EditGuide />} />

      <Route exact path="/add-template" element={<AddTemplate />} />
      <Route exact path="/templates" element={<Templates />} />
      <Route
        exact
        path="/edit-template/:templateId"
        element={<EditTemplate />}
      />
      <Route exact path="/add-media" element={<AddMedia />} />
      <Route exact path="/media" element={<Media />} />

      <Route exact path="/add-post" element={<RefactorAddPost />} />
      <Route exact path="/post" element={<Posts />} />
      <Route exact path="/edit-posts/:postId" element={<EditPost />} />

      <Route exact path="/users" element={<Users />} />
      <Route exact path="/add-user" element={<AddUser />} />

      <Route exact path="/support" element={<Support />} />
      <Route exact path="/support/:ticketId" element={<SupportDetail />} />

      <Route exact path="/reports" element={<AdminReports />} />
    </Routes>
  );
}
