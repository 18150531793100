import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ExitToApp,
  Home,
  People,
  Campaign,
  SupportAgent,
  BarChart,
} from "@mui/icons-material";
import RouteIcon from "@mui/icons-material/Route";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function AdminMenu({ classes }) {
  const { logOut, currentUser } = useAuth();
  return (
    <div>
      <List dense>
        <Link to="/">
          <ListItem>
            <ListItemIcon className={classes.icons}>
              <Home />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Home</ListItemText>
          </ListItem>
        </Link>
        {currentUser.admin && (
          <Link to="/guides">
            <ListItem>
              <ListItemIcon className={classes.icons}>
                <RouteIcon />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Guides</ListItemText>
            </ListItem>
          </Link>
        )}

        {currentUser.admin && (
          <Link to="/post">
            <ListItem>
              <ListItemIcon className={classes.icons}>
                <Campaign />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Promos</ListItemText>
            </ListItem>
          </Link>
        )}
        {currentUser.support_agent && (
          <Link to="/support">
            <ListItem>
              <ListItemIcon className={classes.icons}>
                <SupportAgent />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Support</ListItemText>
            </ListItem>
          </Link>
        )}
        {currentUser.super_admin && (
          <Link to="/reports">
            <ListItem>
              <ListItemIcon className={classes.icons}>
                <BarChart />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Manage Reports
              </ListItemText>
            </ListItem>
          </Link>
        )}
        {currentUser.super_admin && (
          <Link to="/users">
            <ListItem>
              <ListItemIcon className={classes.icons}>
                <People />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Users</ListItemText>
            </ListItem>
          </Link>
        )}
      </List>

      <ListItem>
        <Button
          startIcon={<ExitToApp />}
          onClick={() => logOut()}
          color="primary"
          variant="contained"
        >
          Log Out
        </Button>
      </ListItem>
    </div>
  );
}
